import React from "react";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";

export default function AppCheckbox({
  value,
  onChange = () => {},
  style,
  className,
  color,
  label,
  text,
}) {
  return (
    <div>
      {label && (
        <Label className="mb-3" htmlFor="inputId">
          {label}
        </Label>
      )}
      <Checkbox
        checked={value}
        onCheckedChange={(e) => onChange(!value)}
        style={style}
        className={className}
        color={color}
      >
        {text}
      </Checkbox>
    </div>
  );
}
