import React from "react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHead,
  TableRow,
  TableCell,
} from "../ui/table";
import { Switch } from "../ui/switch";
import Card from "../ui/card-snippet";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { StatusTag, getStatusColor } from "../ui/statusTag";
import AdvancedTablePagination from "./components/advancedTablePagination";
import ActionButton from "./components/actionButton";
import Common from "../../helper/common";

const AdvanceTable = ({
  title,
  columns,
  getData,
  data,
  actionButtons = [],
  showPagination = false,
  addButton,
}) => {
  return (
    <div className="space-y-6">
      <Card title={title} addButton={addButton}>
        <Table>
          <TableHeader>
            <TableRow className="bg-secondary">
            
              {columns.map((column) => (
                <TableHead
                  key={column.key}
                  className="font-semibold whitespace-nowrap"
                >
                  {column.title}
                </TableHead>
              ))}
             {actionButtons.length ? <TableHead className="text-right w-20 sticky right-0 bg-background drop-shadow-md" >Action</TableHead> : ""}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.data.map((item, index) => (
              <TableRow key={index} className="hover:bg-muted bg-background even:bg-default-100">
                {columns.map((column, cellIndex) => {
                  switch (column.type) {
                    case "image":
                      return (
                        <TableCell
                          key={cellIndex}
                          className="font-medium text-card-foreground/80 whitespace-nowrap"
                        >
                          <div className="flex space-x-3 rtl:space-x-reverse items-center">
                            <Avatar className="rounded-lg">
                              <AvatarImage
                                src={
                                  Common.AppConstant.imgBaseurl +
                                  item[column.key]
                                }
                              />
                              <AvatarFallback>AB</AvatarFallback>
                            </Avatar>
                            <span className="text-sm text-default-600">
                              {item.firstName}
                            </span>
                          </div>
                        </TableCell>
                      );
                      case "text":
                          return (
                              <TableCell
                                  className="whitespace-nowrap"
                                  key={cellIndex}
                              >
                                  {column.render ? column.render(item) :
                                      item[column.key]}
                              </TableCell>
                          );
                    case "toggle":
                      return (
                        <TableCell key={cellIndex}>
                          <Switch
                            checked={item[column.key]}
                            onCheckedChange={() => {
                              // column.onChanged ? column.onChanged(item) : null;
                            }}
                          />
                        </TableCell>
                      );
                    case "status":
                      return (
                        <TableCell key={cellIndex}>
                          <StatusTag
                            tagcolor={getStatusColor(item.status)}
                            text={item.status}
                          />
                        </TableCell>
                      );
                    // Add more cases as needed for different types
                    default:
                      return (
                        <TableCell key={cellIndex}>
                          {item[column.key]}
                        </TableCell>
                      );
                  }
                })}
                 {actionButtons.length > 0 && (
                  <TableCell className="text-right sticky right-0 bg-background drop-shadow-md">
                    <div className="flex space-x-0 rtl:space-x-reverse gap-1">
                      {actionButtons.map((action) =>
                        action.type == "edit" ? (
                          <ActionButton
                            icon="heroicons:pencil"
                            onClick={action.onClick?.bind(this, item)}
                          />
                        ) : action.type == "custom" ? (
                          action.button(item)
                        ) : (
                          <ActionButton
                            icon={`heroicons:${
                              action.type == "delete" ? "trash" : "eye"
                            }`}
                            onClick={action.onClick?.bind(this, item)}
                          />
                        )
                      )}
                    </div>
                  </TableCell>
                )}
                {/* <TableCell className="text-right sticky right-0 bg-background drop-shadow-md">
                  <div className="flex space-x-3 rtl:space-x-reverse">
                    <ActionButton
                      icon="heroicons:pencil"
                      onClick={() => updateTrigger(item.id)}
                    />
                  </div>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {showPagination && (
          <AdvancedTablePagination
            currentPage={data.pageNumber}
            onPageChanged={getData}
            totalPages={data.totalPages}
            displayCount={data.totalDisplayed / data.pageNumber}clubcustomers
          />
        )}
      </Card>
    </div>
  );
};
export default AdvanceTable;
