import React from "react";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../ui/select";
import { Label } from "../ui/label";

export default function AppSelect({
  value,
  onChange = () => {},
  placeholder,
  data = [],
  labelKey = "name",
  valueKey = "id",
  label,
  labelButton,
  className,
}) {
  return (
    <div className={className}>
      <div className="flex justify-between">
        {label && (
          <Label className="mb-2" htmlFor="inputId">
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div>
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="z-[9999]">
          {data.map((item) => (
            <SelectItem value={item[valueKey]}>{item[labelKey]}</SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
