import React, { useEffect } from "react";
import { MainSiteLogo } from "../svg";
import common from "../helper/common";
import { useNavigate } from "react-router-dom";
import { RoleEnum } from "../constants/appConstant";

export default function StartPage() {
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      debugger;
      let user = await common.getUser();
      global.user = user;
      if (user) {
        let _route;
        if (global.user.roleId == RoleEnum.Super_Admin) {
          _route = "/app/sports";
        } else if (global.user.roleId == RoleEnum.Club_Manager) {
          _route = "/app/clubBookings";
        }
        navigate(_route, { replace: true });
      } else {
        navigate("/Login", { replace: true });
      }
    })();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <MainSiteLogo className="text-primary h-28" />
    </div>
  );
}
