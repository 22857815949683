import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
// import { useRouter } from "next/navigation";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import { respEnum } from "../../../constants/appConstant";
import { AppSelect } from "../../../components/appComponents";
import Card from "../../../components/ui/card-snippet";
import { useNavigate } from "react-router-dom";

export default function TournamentGrouping() {
  const [tournaments, setTournaments] = useState([]);
  const [tournamentId, setTournamentId] = useState([]);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [tournament, setTournament] = useState({});
  const [groups, setGroups] = useState([]);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getTournaments();
  }, []);

  const getTournaments = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetUnGroupedTournaments"
    );

    let d = response?.data;
    let _data = d.map((tournament) => ({
      ...tournament,
      teams: tournament.teams.map((team) => ({
        ...team,
        isGrouped: false,
        group: "",
      })),
    }));

    setTournaments(_data);
  };

  const changeTournament = (val) => {
    setTournamentId(val);

    let t = tournaments.find((t) => t.id === val) || null;

    if (t) {
      const numberOfGroups = t.group;
      const groups = alphabet.slice(0, numberOfGroups);

      setTournament(t);
      setGroups(groups);
    }
  };

  const handleAddTeamToGroup = () => {
    if (!selectedGroup || !selectedTeam) {
      Common.showToast("Please select both a group and a team.");
      return;
    }

    setTournament((prev) => ({
      ...prev,
      teams: prev.teams.map((team) =>
        team.id === selectedTeam
          ? { ...team, group: selectedGroup, isGrouped: true }
          : team
      ),
    }));

    setSelectedTeam(null);
    setSelectedGroup(null);
  };

  const handleRemoveTeamFromGroup = (teamId) => {
    setTournament((prev) => ({
      ...prev,
      teams: prev.teams.map((team) =>
        team.id === teamId ? { ...team, group: "", isGrouped: false } : team
      ),
    }));

    if (selectedTeam === teamId) setSelectedTeam(null);
  };

  const TeamGrouping = async () => {
    setSaveBtnDisabled(true);

    let data = {
      tournamentId: tournament.id,
      teams: [],
    };

    // 1. Check if the tournament ID exists
    if (!data.tournamentId) {
      Common.showToast("Select Tournament", "Error");
      setSaveBtnDisabled(false);
      return;
    }

    // 2. Check if there are teams in the tournament
    if (!tournament.teams || tournament.teams.length === 0) {
      Common.showToast("No teams available in the tournament", "Error");
      setSaveBtnDisabled(false);
      return;
    }

    // 3. Check if every team is grouped
    const allTeamsGrouped = tournament.teams.every((team) => team.isGrouped);
    if (!allTeamsGrouped) {
      Common.showToast("All teams must be assigned to a group", "Error");
      setSaveBtnDisabled(false);
      return;
    }

    // 4. Check if every group has the same number of teams
    let groupCounts = {};
    tournament.teams.forEach((team) => {
      if (team.group) {
        groupCounts[team.group] = (groupCounts[team.group] || 0) + 1;
      }
    });

    const groupValues = Object.values(groupCounts);
    const allGroupsSameSize = groupValues.every(
      (count) => count === groupValues[0]
    );

    if (!allGroupsSameSize) {
      Common.showToast(
        "Each group must have the same number of teams",
        "Error"
      );
      setSaveBtnDisabled(false);
      return;
    }

    data.teams = tournament.teams.map((team) => ({
      teamId: team.id,
      group: team.group,
    }));

    let resp = await Common.ApiService.getInstance().request(
      "AssignGroupToTeams",
      data,
      "POST"
    );
    if (resp.status == respEnum.Success) {
      Common.SweetAlert.alert(resp.message);
      navigate("/app/tournamentGrouping");
    } else {
      setSaveBtnDisabled(false);
    }
  };

  return (
    <>
      <div className="space-y-6 mb-5">
        <Card title="Schedule Tournament">
          <div className="md:grid md:grid-cols-3 gap-6">
            <div className="flex flex-col col-span-3">
              <div className="md:grid md:grid-cols-3 gap-6">
                <div className="flex flex-col col-span-1">
                  <div className="md:grid md:grid-cols-4 gap-6 space-y-6 md:space-y-0">
                    <div className="flex flex-col col-span-4 gap-2">
                      <AppSelect
                        value={tournamentId}
                        onChange={(val) => changeTournament(val)}
                        placeholder={"Select Tournament"}
                        label={"Select Tournament"}
                        data={tournaments?.map((item) => ({
                          id: item.id,
                          name: item.name,
                        }))}
                      />
                    </div>

                    <div className="flex flex-col col-span-4 gap-2">
                      <div className="md:grid md:grid-cols-4 gap-1 space-y-6 md:space-y-0">
                        {tournament?.teams?.map((item) => (
                          <Button key={item.id}>{item.teamName}</Button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col col-span-2">
                  <div className="md:grid md:grid-cols-4 gap-2 space-y-6 md:space-y-0">
                    <div className="flex flex-col col-span-4">
                      <p className="font-bold text-left text-sm">
                        Add Team in Group <hr className="my-1" />
                      </p>
                    </div>

                    {/* Select Group */}
                    <div className="flex flex-col col-span-1 gap-1">
                      <AppSelect
                        data={groups.map((group) => ({
                          id: group,
                          name: group,
                        }))}
                        placeholder={"Select Group"}
                        value={selectedGroup}
                        label={"Select Group"}
                        onChange={(val) => setSelectedGroup(val)}
                      />
                    </div>

                    {/* Select Team */}
                    <div className="flex flex-col col-span-2 gap-3">
                      <AppSelect
                        data={tournament?.teams
                          ?.filter((f) => !f.isGrouped)
                          .map((item) => ({
                            id: item.id,
                            name: item.teamName,
                          }))}
                        placeholder={"Select Team"}
                        label={"Select Team"}
                        value={selectedTeam}
                        onChange={(val) => setSelectedTeam(val)}
                      />
                    </div>

                    <div className="flex flex-col col-span-1 gap-1">
                      <Button className="mt-6" onClick={handleAddTeamToGroup}>
                        Add Into Group
                      </Button>
                    </div>

                    {/* Display Groups with Teams and Remove option */}
                    {groups.map((group, index) => (
                      <div key={index} className="col-span-4">
                        <p className="font-bold mt-7 text-center text-sm">
                          Group {group} <hr className="my-1" />
                        </p>
                        <div className="relative flex col-span-4 gap-2 justify-center items-center">
                          {tournament?.teams?.filter(
                            (team) => team.group === group
                          ).length > 0 ? (
                            tournament?.teams
                              ?.filter((team) => team.group === group)
                              .map((team) => (
                                <div
                                  key={team.id}
                                  className="relative flex items-center gap-2"
                                >
                                  <Button className="pr-7">
                                    {team.teamName}

                                    <button
                                      onClick={() =>
                                        handleRemoveTeamFromGroup(team.id)
                                      }
                                      className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                                      style={{
                                        background: "none",
                                        border: "none",
                                      }}
                                    >
                                      <FaTimes
                                        className="mt-3 mr-2 "
                                        size={16}
                                      />
                                    </button>
                                  </Button>
                                </div>
                              ))
                          ) : (
                            <p>No Team in this group</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="flex flex-col items-end mt-5">
        <Button onClick={TeamGrouping} disabled={saveBtnDisabled}>
          Save
        </Button>
      </div>
    </>
  );
}
