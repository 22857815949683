import { createBrowserRouter } from "react-router-dom";
import Sports from "../containers/sports";
import PayementSuccessPage from "../containers/paymentSuccess";
import LoginPage from "../containers/auth/login";
import ErrorPage from "../containers/Error";
import StartPage from "../containers";
import MainLayout from "../containers/mainWrapper";
import ClubBookings from "../containers/clubbookings";
import MasterFacilities from "../containers/clubSetup/masterFacilities";
import ClubCustomers from "../containers/clubCustomers";
import Clubs from "../containers/clubs";
import LevelThreshold from "../containers/setup/levelThresholds";
import Countries from "../containers/setup/countries";
import States from "../containers/setup/states";
import Cities from "../containers/setup/cities";
import Courts from "../containers/courts";
import AddCourt from "../containers/courts/addCourt";
import AddCourtHoliday from "../containers/courts/courtHolidays/addCourtHoliday";
import CourtHolidays from "../containers/courts/courtHolidays";
import Transactions from "../containers/transactions";
import TransactionLogs from "../containers/transactions/logs";
import BooingDetails from "../containers/clubbookings/bookingDetails/bookingDetails";
import Tournaments from "../containers/tournament";
import AddTournament from "../containers/tournament/addTournament";
import TournamentGrouping from "../containers/tournament/grouping";
import BookingCalendar from "../containers/clubbookings/calendar/calendar";
import AddSport from "../containers/sports/addSport";

const errorElement = <ErrorPage />;
export const router = createBrowserRouter([
  {
    path: "/",
    element: <StartPage />,
    errorElement,
  },
  {
    path: "/Login",
    element: <LoginPage />,
    errorElement,
  },
  // {
  //   path: "/Sports",
  //   element: <Sports />,
  //   errorElement,
  // },
  {
    path: "/PaymentSuccess",
    element: <PayementSuccessPage />,
    errorElement,
  },
  {
    // Authenticated routes are children of MainLayout, under /app
    path: "/app", // This is the base path for authenticated routes
    element: <MainLayout />, // The main layout wrapping authenticated routes
    errorElement,
    children: [
      {
        index: true,
        path: "sports",
        element: <Sports />,
      },
      {
        index: true,
        path: "addSport",
        element: <AddSport />,
      },
      {
        path: "clubbookings",
        element: <ClubBookings />,
      },
      {
        path: "calendar",
        element: <BookingCalendar />,
      },
      {
        path: "bookingDetails",
        element: <BooingDetails />,
      },
      {
        path: "facilities",
        element: <MasterFacilities />,
      },
      {
        path: "clubcustomers",
        element: <ClubCustomers />,
      },
      {
        path: "clubs",
        element: <Clubs />,
      },
      {
        path: "levelThreshold",
        element: <LevelThreshold />,
      },
      {
        path: "countries",
        element: <Countries />,
      },
      {
        path: "states",
        element: <States />,
      },
      {
        path: "cities",
        element: <Cities />,
      },
      {
        path: "courts",
        element: <Courts />,
      },
      {
        path: "addCourt",
        element: <AddCourt />,
      },
      {
        path: "courHolidays",
        element: <CourtHolidays />,
      },
      {
        path: "addCourtHoliday",
        element: <AddCourtHoliday />,
      },
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "transactionLogs",
        element: <TransactionLogs />,
      },
      {
        path: "tournaments",
        element: <Tournaments />,
      },
      {
        path: "addTournament",
        element: <AddTournament />,
      },
      {
        path: "tournamentGrouping",
        element: <TournamentGrouping />,
      },
      {
        path: "tournamentscheduling",
        element: <TournamentGrouping />,
      },

      // Add more authenticated routes here
    ],
  },
]);
