// contexts/AuthContext.js
import { createContext, useContext, useState, useEffect } from "react";
import common from "../helper/common";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      console.log("location", window.location.href);
     
      let _user = await common.getUser();
      global.user = _user;
      console.log("_user", _user);
      setUser(_user);
      if(window.location.href.includes("app") && !_user?.id){
        window.location.replace("/Login")
        // navigate("/", {replace:true});
      }
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
