import { useEffect, useState } from "react";
import Card from "../ui/card-snippet";
import { CardContent } from "../ui/card";
import Common from "../../helper/common";
import { AppInput, AppSwitch } from "../appComponents";
import { DayOfWeekEnum } from "../../constants/appConstant";

const TimingsCard = ({ name, formikProps, data }) => {
    const [timings, setTimings] = useState([]);

    useEffect(() => {
        setTimings(data);
    }, [data]);

    // useEffect(() => {
    //   const currentFormikValue = formikProps.values[name];

    //   if (JSON.stringify(currentFormikValue) !== JSON.stringify(timings) && currentFormikValue) {
    //     setTimings(currentFormikValue);
    //   }
    // }, [formikProps.values[name]]);

    useEffect(() => {
        const currentFormikValue = formikProps.values[name];

        if (
            JSON.stringify(currentFormikValue) !== JSON.stringify(timings) &&
            timings
        ) {
            formikProps.setFieldValue(name, timings);
        }
    }, [timings]);

    const handleChange = (id, field, value) => {
        setTimings((prevTimings) =>
            prevTimings.map((item) =>
                item.id === id ? { ...item, [field]: value } : item
            )
        );
    };

  return (
    <div className="flex flex-col gap-2">
      <Card title="Timings">
        <CardContent className="p-3">
          {timings.map((item, index) => (
            <div key={index} className="md:grid md:grid-cols-4 gap-2 mb-2">
              <AppSwitch
                value={item.isOn}
                className="border-default-300"
                onChange={(val) => handleChange(item.id, "isOn", val)}
              />
              <div className="flex items-center">
                {Common.Utility.getKeyByValue(DayOfWeekEnum, item.dayOfweek)}
              </div>

                            <AppInput
                                size="sm"
                                type="time"
                                value={item.openingTime}
                                onChange={(val) => handleChange(item.id, "openingTime", val)}
                                onBlur={(val) => Common.Utility.handleBlur(val, item.id, "openingTime")}
                                className="mb-1 mr-1"
                                disabled={!item.isOn}
                            />

                            <AppInput
                                size="sm"
                                type="time"
                                value={item.closingTime}
                                onChange={(val) => handleChange(item.id, "closingTime", val)}
                                onBlur={(val) => Common.Utility.handleBlur(val, item.id, "closingTime")}
                                className="mb-1 ml-1"
                                disabled={!item.isOn}
                            />
                        </div>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
};

export default TimingsCard;
