import Select from "react-select";
import ErrorText from "./error-text";
import { Label } from "../ui/label";
import { useMemo } from "react";

export default function FormikSearchSelect({
  formikProps,
  onChange = () => {},
  name,
  placeholder,
  data = [],
  labelKey = "name",
  valueKey = "id",
  label,
  labelButton,
  style,
  className,
  isMulti = false,
  removeWrapper = false,
}) {
  const dataList = useMemo(
    () =>
      data?.map((item) => ({
        value: item[valueKey],
        label: item[labelKey],
      })),
    [data, labelKey, valueKey]
  );

  const defaultValue = useMemo(() => {
    if (isMulti) {
      return formikProps.values[name]?.map((value) =>
        dataList.find((item) => item.value === value)
      );
    } else {
      return dataList.find((item) => item.value === formikProps.values[name]);
    }
  }, [dataList, formikProps.values[name], isMulti]);

  const handleChange = (selectedOption) => {
    const value = isMulti
      ? selectedOption?.map((option) => option.value)
      : selectedOption?.value;

    formikProps.setFieldValue(name, value);
    onChange(value);
  };

  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <Label className="mb-3" htmlFor={name}>
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div>
      <Select
        className="react-select"
        value={defaultValue}
        onChange={handleChange}
        options={dataList}
        isMulti={isMulti}
        placeholder={placeholder}
        styles={style}
        classNamePrefix={className}
      />
      <ErrorText name={name} formikProps={formikProps} />
    </div>
  );
}
