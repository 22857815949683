import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import {
  TournamentVenueTypeEnum,
  ViewDateFormat,
} from "../../constants/appConstant";
import AdvanceTable from "../../components/advanceTable";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";

const Tournaments = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetTournaments",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        sportId: null,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          joinStatus: `${item.teamsJoined}/${item.maxTeams} teams joined`,
          venueType: Common.Utility.getKeyByValue(
            TournamentVenueTypeEnum,
            item.venueType
          ),
          registrationDates: `${moment(item.registrationStartDateTime).format(
            ViewDateFormat
          )} - ${moment(item.registrationEndDateTime).format(ViewDateFormat)}`,
          playingDates: `${moment(item.startDateTime).format(
            ViewDateFormat
          )} - ${moment(item.endDateTime).format(ViewDateFormat)}`,
        })),
      };

      setData(obj);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        console.log(val);
        navigate(`/app/addTournament?id=${val.id}`, {});
      },
    },
  ];

  let columns = [
    { title: "Icon", type: "image", key: "icon" },
    { title: "Tournament Name", type: "text", key: "name" },
    { title: "Sport Type", type: "text", key: "sportName" },
    { title: "Join Status", type: "text", key: "joinStatus" },
    { title: "Entry Fee", type: "text", key: "entryFee" },
    { title: "Type", type: "text", key: "venueType" },
    { title: "Registration Dates", type: "text", key: "registrationDates" },
    { title: "Playing Dates", type: "text", key: "playingDates" },
  ];

  const _addBtn = () => (
    <Button onClick={() => navigate("/app/addTournament")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Tournament
    </Button>
  );

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={"Tournaments"}
          columns={columns}
          data={data}
          getData={getData}
          addButton={_addBtn}
          showPagination
          showAddButton
          actionButtons={actions}
          updateTrigger={_addUpdateTrigger} // Example function
        />
      )}
    </>
  );
};

export default Tournaments;
